// @flow

import React, { Fragment } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { BACKGROUND_COLOR } from "constants/colors";
import { pasapalabrasCreatorTemplate } from "constants/dataTest";
import { SHADOW, BORDER_RADIUS } from "constants/styles";
import { genmagicMail } from "constants/strings";
import { Title as PasapalabrasTitle, Level as PasapalabrasLevel, Title } from "./PasapalabrasApp";
import PrimaryButton from "components/PrimaryButton";
import PasapalabrasTest from "components/PasapalabrasTest";
import media from "media";
import getUserIsAdmin from "utils/auth-utils";
import { getRouteParam } from "utils/route-utils";
import { postPasapalabras, putPasapalabras, getPasapalabrasById } from "services/pasapalabras-api-communicator"


const LOCALSTORAGE_CREATOR_CREATE_PASAPALABRASDATA_KEY = "creator-create-pasapalabrasData"
const LOCALSTORAGE_CREATE_EDIT_PASAPALABRASDATA_KEY = "creator-edit-pasapalabrasData"

const persist = (key, value) => {
  if (typeof value == "object")
    localStorage.setItem(key, JSON.stringify(value))
}


export class FileSelector extends React.Component {
  handleFileChosen = f => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.props.onFileSelected(JSON.parse(fileReader.result));
    };
    fileReader.readAsText(f);
  };

  render() {
    return (
      <input
        type="file"
        accept=".json"
        onChange={e => this.handleFileChosen(e.target.files[0])}
      />
    );
  }
}

class CreatorApp extends React.Component {
  constructor(props) {
    super(props);
    this.localStorageKey = this.props.isCreating ? LOCALSTORAGE_CREATOR_CREATE_PASAPALABRASDATA_KEY : LOCALSTORAGE_CREATE_EDIT_PASAPALABRASDATA_KEY
    this.state = {
      publishedPasapalabrasData: null,
      pasapalabrasData: pasapalabrasCreatorTemplate
    };
  }

  save = () => {
    persist(this.localStorageKey, this.state.pasapalabrasData)
  }

  componentDidMount = () => {
    this.fetchData()
  }

  async fetchData() {
    const pasapalabrasId = getRouteParam(this.props, "pasapalabrasId")
    const pasapalabrasData = (this.props.isEditing && pasapalabrasId) ?
      await getPasapalabrasById(pasapalabrasId)
      :
      JSON.parse(localStorage.getItem(this.localStorageKey)) || pasapalabrasCreatorTemplate

    this.setState({
      pasapalabrasData: pasapalabrasData,
      pasapalabrasId: pasapalabrasId
    })
  }

  downloadObjectAsJson = (obj, exportName) => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  isFormValid = () => {
    const form = document.forms["new-pasapalabras-form"]
    return form.checkValidity() && this.state.pasapalabrasData.solutions.length >= 3
  }

  onDownloadClicked = () => {
    if (this.isFormValid()) {
      this.downloadObjectAsJson(
        this.state.pasapalabrasData,
        this.state.pasapalabrasData.title
      );
    }
  };

  onPublishClicked = async (update = false) => {
    if (this.isFormValid()) {
      const publishedPasapalabrasData = update ? await putPasapalabras(this.state.pasapalabrasId, this.state.pasapalabrasData, this.props.accessToken) :
        await postPasapalabras(this.state.pasapalabrasData, this.props.accessToken)
      this.setState({ publishedPasapalabrasData: publishedPasapalabrasData })
    }
  }

  handleColorChange = c => {
    const color = c.hex;
    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        color: color
      }
    });
    this.save()
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        [name]: value
      }
    });
    this.save()
  };

  handleChange_solutions = e => {
    const name = e.target.name;
    const value = e.target.value;
    const i = e.target.getAttribute("index") || e.target.getAttribute("dir"); // hack because textarea doesn't support new HTML attributes
    let solutions = this.state.pasapalabrasData.solutions;
    solutions[i][name] = value;
    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        solutions: solutions
      }
    });
    this.save()
  };
  handleChange_elementTexts = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        elementTexts: {
          ...this.state.pasapalabrasData.elementTexts,
          [name]: value
        }
      }
    });
    this.save()
  };

  add_row = () => {
    let solutions = this.state.pasapalabrasData.solutions;

    const char = (solutions[solutions.length - 1] || {}).letter

    solutions.push({
      letter: char ? String.fromCharCode(char.charCodeAt() + 1) : "A", // Next char
      phrase: "",
      word: ""
    });
    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        solutions: solutions
      }
    });
    this.save()
  };

  remove_row = () => {
    let solutions = this.state.pasapalabrasData.solutions;
    solutions.pop();
    this.setState({
      pasapalabrasData: {
        ...this.state.pasapalabrasData,
        solutions: solutions
      }
    });
    this.save()
  };

  onFileSelected = data => {
    this.setState({
      publishedPasapalabrasData: null,
      pasapalabrasData: {
        lang: data.lang || "",
        title: data.title || "",
        level: data.level || "",
        author: data.author || "",
        image: data.image || "",
        color: data.color || "",
        solutions: data.solutions || this.state.pasapalabrasData.solutions
      }
    });
    this.save()
  };

  render() {
    const { pasapalabrasData, publishedPasapalabrasData } = this.state;
    const { user } = this.props;
    const isAdmin = getUserIsAdmin(user);

    const genmagicLink = (<StyledLink
      rel="noopener noreferrer"
      href={"mailto:" + genmagicMail}
      target="_blank"
    >{genmagicMail}</StyledLink>)

    return (
      <Wrapper>
        <div>
          <Title>Crea tu pasapalabras</Title>
        </div>
        <Wrapper3>
          <form
            name="new-pasapalabras-form"
            onSubmit={e => {
              e.preventDefault();
              // this.onDownloadClicked();
            }}
          >
            <StyledFieldset>
              <Information>
                <FieldBox>
                  <H3>1.- Editar pasapalabras (Opcional)</H3>
                  <div>
                    (Solo si ya creaste un pasapalabras anteriormente y quieres
                    modificarlo)
                  </div>
                  <FileSelector onFileSelected={this.onFileSelected} />
                  <H3>2.- Información</H3>
                  <div> Lengua:</div>
                  <select
                    name="lang"
                    onChange={this.handleChange}
                    value={pasapalabrasData.lang}
                  >
                    <option value="es">Castellano</option>
                    <option value="ca">Català</option>
                    <option value="gl">Galego</option>
                    <option value="eu">Euskera</option>
                    <option value="en">English</option>
                    <option value="de">Deutsch</option>
                  </select>
                  <i style={{
                    fontSize: "1rem",
                  }}> ¿Echas de menos un idioma? ¡Háznoslo saber! ➜ {genmagicLink}</i>
                  <div> Título:</div>
                  <TextInput
                    name="title"
                    placeholder="Ej. Los animales"
                    value={pasapalabrasData.title}
                    onChange={this.handleChange}
                    maxLength={32}
                    required
                  />
                  <div> Nivel:</div>
                  <TextInput
                    name="level"
                    placeholder="Ej. Bachillerato"
                    value={pasapalabrasData.level}
                    onChange={this.handleChange}
                    maxLength={32}
                    required
                  />
                  <div> Colaborador de contenido:</div>
                  <TextInput
                    name="author"
                    placeholder="Ej. Menganito Fulánez"
                    value={pasapalabrasData.author}
                    onChange={this.handleChange}
                    required
                  />
                </FieldBox>
              </Information>

              <H3>3.- Contenido</H3>
              {pasapalabrasData.solutions.map(({ letter, phrase, word }, i) => (
                <Fragment key={i}>
                  <Item>
                    <span>
                      <div>Letra:</div>
                      <div>
                        <TextInput
                          dir={i}
                          name="letter"
                          placeholder="Ej. A"
                          value={letter.toUpperCase()}
                          onChange={this.handleChange_solutions}
                          maxLength="2"
                          required
                        />
                      </div>
                      <div>Respuesta:</div>
                      <div>
                        <TextInput
                          dir={i}
                          name="word"
                          placeholder="Ej. Abeja"
                          value={word}
                          onChange={this.handleChange_solutions}
                          required
                        />
                      </div>
                    </span>
                    <span style={{ width: "100%", marginLeft: "1rem" }}>
                      <div>Descripción:</div>
                      <div>
                        <StyledTextarea
                          dir={i}
                          rows={4}
                          name={"phrase"}
                          placeholder="Ej. Insecto de color negro y amarillo."
                          value={phrase}
                          onChange={this.handleChange_solutions}
                          required
                          type="text"
                        />
                      </div>
                    </span>
                  </Item>
                  {i < Object.keys(pasapalabrasData.solutions).length - 1 && <hr style={{ borderStyle: "dashed" }} />}
                </Fragment>
              ))}
              <div style={{ color: "red" }}>{this.state.pasapalabrasData.solutions.length < 3 && "El pasapalabras debe tener mínimo 3 palabras"}</div>
              <StyledPrimaryButton
                onClick={e => {
                  e.preventDefault();
                  this.add_row();
                }}
              >
                <Symbol>+</Symbol>
              </StyledPrimaryButton>
              <StyledPrimaryButton
                onClick={e => {
                  e.preventDefault();
                  this.remove_row();
                }}
              >
                <Symbol>-</Symbol>
              </StyledPrimaryButton>
            </StyledFieldset>
            <H3>4.- Personaliza tu pasapalabras</H3>
            <FieldBox>
              <div>Color de fondo:</div>
              <div style={{ display: "flex" }} >
                <SketchPicker
                  width="100%"
                  disableAlpha
                  color={pasapalabrasData.color}
                  onChange={this.handleColorChange}
                />
                <ColorTest color={pasapalabrasData.color} />
              </div>
              <div> Imagen:</div>
              <TextInput
                name="image"
                placeholder="Ej. https://example.com/image.jpg"
                value={pasapalabrasData.image}
                onChange={this.handleChange}
                pattern="^$|^(https?:\/\/.*\.(?:png|jpg|gif))$"
              />
              <i style={{
                fontSize: "1rem",
              }}> Introduce la url de una imagen</i>
            </FieldBox>
            <H3>5.- Revisión</H3>
            Revisa que todo esté correcto (ortografía, gramática, contenido...).
            <TestWrapper
              color={this.state.pasapalabrasData.color}
            >
              <div
                style={{
                  marginBottom: "1.5rem",
                  textAlign: "center"
                }}
              >
                <PasapalabrasTitle>{this.state.pasapalabrasData.title}</PasapalabrasTitle>
                <PasapalabrasLevel>{this.state.pasapalabrasData.level}</PasapalabrasLevel>
              </div>
              <PasapalabrasTest
                sizeDesktop={CREATOR_SIZE_DESKTOP}
                solutions={this.state.pasapalabrasData.solutions}
                button_pasaPalabra={"➜"}
                button_check={"button_check"}
                image={this.state.pasapalabrasData.image}
                textBox_answer={"textBox_answer"}
              />
            </TestWrapper>
            <H3>6.- Descargar y enviar</H3>
            <span>
              Haz click en "Descargar" y envía el fichero descargado a{" "}
            </span>
            {genmagicLink}
            <br /><br />
            <StyledPrimaryButton onClick={this.onDownloadClicked}>Descargar</StyledPrimaryButton>
            {isAdmin &&
              <Fragment>
                {!publishedPasapalabrasData ?
                  <Fragment>
                    <span style={{ borderLeft: "1px solid", marginRight: "1rem" }} />
                    <StyledPrimaryButton onClick={() => this.onPublishClicked(this.state.pasapalabrasId)} title="Solo visible para usuarios admin">{this.state.pasapalabrasId ? "Actualizar" : "Publicar"}</StyledPrimaryButton>
                  </Fragment>
                  :
                  <Fragment>
                    <div>Pasapalabras publicado</div>
                    <ol>
                      <li>id: <code>{publishedPasapalabrasData._id}</code></li>
                      <li>filenameId: {publishedPasapalabrasData.filenameId}</li>
                      <li>createdAt: {publishedPasapalabrasData.createdAt}</li>
                    </ol>
                  </Fragment>
                }
              </Fragment>
            }
          </form>
        </Wrapper3>
      </Wrapper >
    );
  }
}



const ColorTest = styled.div`
  margin-left: 1rem;
  background-color: ${props => props.color};
  border-radius: 5px;
  box-shadow: ${SHADOW};
  width: 25%;
  ${media.laptop`
    width: 100%;
  `}
`

const CREATOR_SIZE_DESKTOP = "600px"

const TestWrapper = styled.div`
  margin: 1rem 0;
  padding: 2rem;
  background-color: ${props => props.color};
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${SHADOW};

  ${media.laptop`
    width: ${CREATOR_SIZE_DESKTOP};
  `}
`

const H3 = styled.h3`
  margin-top: 2rem;
  border-bottom: 1px solid white;
`;

const StyledLink = styled.a`
  :link {
    color: white;
  }
  :visited {
    color: white;
  }
  :hover {
    color: grey;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 1rem;
`;
const Symbol = styled.div`
  font-size: 2rem;
`;

const StyledTextarea = styled.textarea`
  width: fill-available;
  height: 5rem;
`;

const Item = styled.div`
  > span > div {
    margin: 0.25rem 0;
  }
  ${media.laptop`
    display: flex;
    justify-content: space-between;
  `}
`;
const Wrapper = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  justify-content: center;
  color: white;

  background-color: ${BACKGROUND_COLOR};

  height: 100%;
  width: 100%;
`;
const Wrapper3 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${media.laptop`
    flex-direction: row;
  `}
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop`
    flex-direction: row;
  `}
`;



const TextInput = (props) => {
  return <StyledTextInput type="text" {...props} />;
};

const StyledTextInput = styled.input`
  width: fill-available;
  ${media.laptop`
      width: 250px;
    `}
`;

const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin-bottom: 1rem;
`;
const FieldBox = styled.div`
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  > div {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }
`;

export default CreatorApp;
