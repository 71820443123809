// @flow
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { BACKGROUND_COLOR } from "constants/colors"
import { getAllPasapalabras } from "services/pasapalabras-api-communicator"
import { BORDER_RADIUS, SHADOW } from "constants/styles";
import PrimaryButton from "components/PrimaryButton";
import { Title } from "./PasapalabrasApp";
import Spinner from "components/Spinner";

export default function OverviewPage() {

  const [pasapalabras, setPasapalabras] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const pasapalabras = await getAllPasapalabras()
      setPasapalabras(pasapalabras)
    }
    fetchData()
  }, [])

  return (
    <Wrapper>
      <div style={{ textAlign: "center" }}><Title>Pasapalabras</Title></div>

      <NavLink to="/crear"><PrimaryButton>Crear Pasapalabras</PrimaryButton></NavLink>

      <Fragment>
        <h1>Cantidad de pasapalabras {pasapalabras ? pasapalabras.length : <Spinner size="1rem" color="white" />}</h1>
        {pasapalabras ? pasapalabras.map((e, i) => <PasapalabrasSummary key={i} {...e}></PasapalabrasSummary>) : <Spinner color="white" />}
      </Fragment>
    </Wrapper>
  )
}

const PasapalabrasSummary = (s) => {
  return (
    <NavLink to={"/" + s._id}>
      <button
        style={{
          backgroundColor: "white",
          width: "20rem",
          borderRadius: BORDER_RADIUS,
          boxShadow: SHADOW,
          padding: "1rem",
          margin: "1rem",
          cursor: "pointer"
        }}
      >
        <b style={{ fontSize: "1.25rem" }} >{s.title}</b>
        <div>{s.level}</div>
        <i>{s.author}</i>

      </button>
    </NavLink>
  )
}

const Wrapper = styled.div`
  background-color: ${BACKGROUND_COLOR};
  position: absolute;
  padding: 1rem;
`;