import { PASAPALABRASDATATEST } from "constants/dataTest";
import config from "../config/backend-config"

// Fetch all existing pasapalabras summaries
export async function getAllPasapalabras() {
    const uri = config.pasapalabrasUrl

    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.status !== 200) return
        const res = await response.json()
        return res
    }
    catch (e) {
        console.error(e)
    }
}

export async function putPasapalabras(pasapalabrasId, data, accessToken) {
    const uri = config.pasapalabrasUrl + pasapalabrasId
    try {
        const response = await fetch(uri, {
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data)
        });
        if (response.status !== 200) {
            const res = await response.json()
            console.error(res)
        } else {
            const res = await response.json()
            return res
        }
    }
    catch (e) {
        console.error(e)
    }
}
export async function postPasapalabras(data, accessToken) {
    const uri = config.pasapalabrasUrl
    try {
        const response = await fetch(uri, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data)
        });
        if (response.status !== 200) return
        const res = await response.json()
        return res
    }
    catch (e) {
        console.error(e)
    }
}

export async function getPasapalabrasByFilenameId(filenameId) {
    const uri = config.pasapalabrasUrl + "filenameId/" + filenameId

    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.status !== 200) return
        const res = await response.json()
        return res
    }
    catch (e) {
        console.error(e)
    }
}
export async function getPasapalabrasById(id) {
    const uri = config.pasapalabrasUrl + id

    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response.status !== 200) return
        const res = await response.json()
        return res
    }
    catch (e) {
        console.error(e)
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// Fetch from genmagic bluehost
export async function getPasapalabrasByFilenameIdOld(filenameId) {
    const uri = "https://genmagic.net/pasapalabras_app/data_bank/" + filenameId + ".json"

    if (process.env.NODE_ENV === "development") {
        console.log("mocking getPasapalabrasByFilenameIdOld", filenameId)
        await sleep(1000)
        return PASAPALABRASDATATEST
    }

    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.status !== 200) return
        const res = await response.json()
        console.log(res)
        return res
    }
    catch (e) {
        console.error(e)
    }
}
