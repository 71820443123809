// @flow

import React from 'react';

import styled from "styled-components"
class ErrorApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      s: 5
    };
  }

  interval = setInterval(() => {
    this.setState({
      s: this.state.s - 1
    })
    if (this.state.s <= 0)
      clearInterval(this.interval)
  }, 1000);

  render() {
    if (this.state.s <= 0) {
      window.location.replace("https://genmagic.org");
    }
    return (
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "8rem" }}>404</h1>
        <h2 style={{ fontSize: "3rem", fontStyle: "italics" }}>Puff!</h2>
        <div style={{ fontSize: "1.5rem" }}>
          <div >Nadie ha programado esta página ...</div>
          <div> Si crees que deberíamos haber encontrado algo aquí, háznoslo saber!</div>
          <br />
          {this.state.s > 0 ?
            <i> Te redirigimos a <StyledLink href={"genmagic.org"}>genmagic.org</StyledLink> en {this.state.s} s</i>
            : <i> Redirigiendo a <StyledLink href={"genmagic.org"}>genmagic.org</StyledLink> ... </i>}
        </div>
      </div>
    )
  }
}

const StyledLink = styled.a`
  :link {
    color: white;
  }
  :visited {
    color: white;
  }
  :hover {
    color: grey;
  }
`;

export default ErrorApp;
