// @flow

import React, { Fragment } from "react";
import styled from "styled-components";

import Scoreboard from "../components/Scoreboard";
import Pasapalabras from "../components/Pasapalabras";
import AboutPopup from "../components/AboutPopup";
import media from "../media";
import { PASAPALABRASDATATEST } from "../constants/dataTest";
import { BACKGROUND_COLOR } from "../constants/colors";
import PrimaryButton from "../components/PrimaryButton";
import { typewritter, fadein } from "../constants/animations";
import EndGameReport from "../components/Pasapalabras/EndGameReport";
import { STRINGS } from "../constants/strings";
import { getRouteParam } from "../utils/route-utils";
import {
  LETTER_SIZE_MOBILE, LETTER_SIZE,
  HEADER_SIZE_MOBILE, HEADER_SIZE_TABLET, HEADER_SIZE_DESKTOP
} from "../constants/sizes";

import {
  // getPasapalabrasByFilenameId,
  getPasapalabrasByFilenameIdOld,
  getPasapalabrasById
} from "../services/pasapalabras-api-communicator"


class PasapalabrasApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      isGameEnd: false,
      fails: 0,
      hits: 0,
      seconds: 0,
      minutes: 0,
      isButtonPopupShown: false,
      history: {},
    };
  }

  componentDidMount() {
    // if (process.env.NODE_ENV === "development") this.fakeFetchData();
    // else
    this.fetchData();
  }

  async fetchData() {

    const pasapalabrasId = getRouteParam(this.props, "pasapalabrasId")
    let pasapalabrasData = null
    if (pasapalabrasId){
      pasapalabrasData = await getPasapalabrasById(pasapalabrasId)
}
    const searchParams = new URLSearchParams(window.location.search)
    // const _id = searchParams.get("_id")
    // const fid = searchParams.get("fid")
    const oldId = searchParams.get("id")
    // if (_id)
    //   pasapalabrasData = await getPasapalabrasById(_id)
    // else if (fid)
    //   pasapalabrasData = await getPasapalabrasByFilenameId(fid)
    if (oldId) {
      pasapalabrasData = await getPasapalabrasByFilenameIdOld(oldId)
    }


    this.setState({ pasapalabras: pasapalabrasData });
  }
  fakeFetchData() {
    setTimeout(() => this.setState({ pasapalabras: PASAPALABRASDATATEST }), 1000);
  }

  addToHistory = (letterIndex, attepmt, isCompleted) => {
    let letterHistory = this.state.history[letterIndex] || {};
    let letterAttempts = letterHistory.attempts || [];
    letterAttempts.push(attepmt);
    letterHistory.isCompleted = isCompleted;
    letterHistory.attempts = letterAttempts;
    this.setState({
      history: { ...this.state.history, [letterIndex]: letterHistory },
    });
  };

  setPlayingStatus = (status) => {
    this.setState({ isPlaying: status });
  };
  setGameEnd = (status) => {
    this.setState({ isGameEnd: status });
  };

  increaseFails = () => {
    this.setState({ fails: this.state.fails + 1 });
  };
  increaseHits = () => {
    this.setState({ hits: this.state.hits + 1 });
  };

  resetScores = () => {
    this.setState({
      fails: 0,
      hits: 0,
      seconds: 0,
      minutes: 0,
    });
  };

  tickTimer = () => {
    if (!this.state.isPlaying) return;

    if (this.state.seconds + 1 < 60) {
      this.setState({ seconds: this.state.seconds + 1 });
    } else {
      this.setState({ seconds: 0, minutes: this.state.minutes + 1 });
    }
  };

  startTimer = () => {
    clearInterval(this.timer);
    this.timer = setInterval(this.tickTimer, 1000);
  };

  toggleShowAboutPopup = () => {
    this.setState({ isButtonPopupShown: !this.state.isButtonPopupShown });
  };

  onGameEndClicked = () => {
    this.setGameEnd(true);
    this.setPlayingStatus(false);
  };

  render() {
    const {
      isPlaying,
      isGameEnd,
      hits,
      fails,
      minutes,
      seconds,
      isButtonPopupShown,
      pasapalabras,
      history,
    } = this.state;


    const title = pasapalabras ? pasapalabras.title || "" : "";
    const level = pasapalabras ? pasapalabras.level || "" : "";
    const image = pasapalabras ? pasapalabras.image || "" : "";
    const author = pasapalabras ? pasapalabras.author || "" : "";
    const bgColor = pasapalabras
      ? pasapalabras.color || BACKGROUND_COLOR
      : BACKGROUND_COLOR;
    const solutions = pasapalabras ? pasapalabras.solutions || [] : [];
    const lang = pasapalabras ? pasapalabras.lang || "es" : "es";

    const strings = STRINGS[lang];

    const button_pasaPalabra = strings.button_pasaPalabra || "";
    const button_check = strings.button_check || "";
    const textBox_answer = strings.textBox_answer || "";
    const endgameReport_title = strings.endgameReport_title || "";
    const end_text = strings.end || "";
    const restart_text = strings.restart || "";
    const results_text = strings.results || "";
    const completed_words_text = strings.completed_words || "";
    const non_completed_words_text = strings.non_completed_words || "";
    const wrong_attempts_text = strings.wrong_attempts || "";
    const time_text = strings.time || "";
    const start_text = strings.start || "";
    const history_text = strings.history || "";

    // hack
    document.body.style.transition = "background-color 1000ms linear";
    document.body.style.backgroundColor = bgColor;

    const globalAuthor = "genmagic.org";

    return (
      <Wrapper bgColor={bgColor}>
        <div> {/* header  */}
          <CornerButton onClick={this.toggleShowAboutPopup}>
            <i className="material-icons">info_outline</i>
          </CornerButton>
          <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
            {isPlaying && (
              <PrimaryButton onClick={this.onGameEndClicked}>
                {end_text}
              </PrimaryButton>
            )}
          </div>
          {isButtonPopupShown && (
            <AboutPopup
              imageUrl={image}
              pasapal
              author={author}
              globalAuthor={globalAuthor}
              toggleShowAboutPopup={this.toggleShowAboutPopup}
            />
          )}
          <Info>
            {isPlaying ? (
              <Scoreboard
                hits={hits}
                fails={fails}
                seconds={seconds}
                minutes={minutes}
              />
            ) : (
                <div style={{ textAlign: "center" }}>
                  {title && <Title>{title}</Title>}
                  {level && <Level>{level}</Level>}
                </div>
              )}
          </Info>
        </div> {/* header - end */}
        {!isGameEnd ? (
          <Pasapalabras
            isPlaying={isPlaying}
            isGameEnd={isGameEnd}
            startTimer={this.startTimer}
            increaseFails={this.increaseFails}
            increaseHits={this.increaseHits}
            resetScores={this.resetScores}
            setPlayingStatus={this.setPlayingStatus}
            setGameEnd={this.setGameEnd}
            addToHistory={this.addToHistory}
            solutions={solutions}
            button_pasaPalabra={button_pasaPalabra}
            button_check={button_check}
            image={image}
            textBox_answer={textBox_answer}
            start_text={start_text}
          />
        ) : (
            <Fragment>
              <EndGameReport
                history={history}
                solutions={solutions}
                hits={hits}
                fails={fails}
                minutes={minutes}
                seconds={seconds}
                endgameReport_title={endgameReport_title}
                results_text={results_text}
                completed_words_text={completed_words_text}
                non_completed_words_text={non_completed_words_text}
                wrong_attempts_text={wrong_attempts_text}
                time_text={time_text}
                history_text={history_text}
              />
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    this.setPlayingStatus(true);
                    this.setGameEnd(false);
                    this.resetScores();
                    this.startTimer();
                  }}
                >
                  {restart_text}
                </PrimaryButton>
              </div>
            </Fragment>
          )}
        <AuthorWrapper>
          <Author
            rel="noopener noreferrer"
            target="_blank"
            href={"https://www." + globalAuthor}
          >
            {globalAuthor}
          </Author>
        </AuthorWrapper>
      </Wrapper>
    );
  }
}

export const Level = styled.div`
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: ${typewritter} 2s;
  animation-fill-mode: forwards;

  ${media.tablet`
    font-size: 1.5rem;
  `}
`;

export const Title = styled.div`
  font-variant: small-caps;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px black;
  font-size: 1.5rem;
  animation: ${fadein} 2s;
  animation-fill-mode: forwards;

  ${media.tablet`
      font-size: 3rem;
    `}
`;

const Info = styled.div`
  display: flex;
  padding: 1rem 4rem;
  display: flex;
  justify-content: center;
  height: ${HEADER_SIZE_MOBILE};
  padding-bottom: ${LETTER_SIZE_MOBILE};
  box-sizing: border-box;
  ${media.tablet`
    height: ${HEADER_SIZE_TABLET};
    padding-bottom: ${LETTER_SIZE};
  `}
  ${media.laptop`
    height: ${HEADER_SIZE_DESKTOP};
    padding-bottom: ${LETTER_SIZE};
  `}
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bgColor};
  transition: background-color 1000ms linear;
`;

const CornerButton = styled.a`
  position: absolute;
  left: 1rem;
  top: 1rem;
  cursor: pointer;
  color:white;
  z-index: 2;
  width: 48px;
  height: 48px;
  text-shadow: 2px 2px black;
  
  &:focus {
    outline:0;
  } 
`;

const AuthorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  position: absolute;
  bottom: 0;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const Author = styled.a`
  color: white;
  font-style: italic;
`;
export default PasapalabrasApp;
