
const liveConfig = {
    genmagicApiServer: "https://genmagic-backend.herokuapp.com",
}

const localConfig = {
    genmagicApiServer: "http://127.0.0.1:5000",
}

const getEnvConfig = (env) => {
    switch (env) {
        case "LIVE":
            return liveConfig
        case "LOCAL":
        default:
            return localConfig
    }
}

const ENV = "LIVE"
const config = getEnvConfig(ENV)
config.pasapalabrasUrl = config.genmagicApiServer + "/pasapalabras/"
export default config