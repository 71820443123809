export const MAX_SIZE_PASAPALABRAS = "900px"

export const HEADER_SIZE_MOBILE = "7rem"
export const HEADER_SIZE_TABLET = "8rem"
export const HEADER_SIZE_DESKTOP = "9rem"

export const MIN_PRIMARY_BUTTON_SIZE = "64px"
export const MIN_PRIMARY_BUTTON_SIZE_MOBILE = "48px"

export const LETTER_SIZE = "2.5rem"
export const LETTER_SIZE_MOBILE = "1.5rem"

export const PASAPALABRAS_SIZE_MOBILE =  `calc(100vw - ${LETTER_SIZE_MOBILE} - 2rem)`
export const PASAPALABRAS_SIZE_DESKTOP = `calc(100vh - ${HEADER_SIZE_DESKTOP} - ${LETTER_SIZE} - 1rem)`