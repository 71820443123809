export const genmagicMail = "genmagic@gmail.com"

export const STRINGS = {
  es: {
    button_pasaPalabra: "Pasa Palabra",
    button_check: "Comprueba",
    textBox_answer: "Tu respuesta aquí :)",
    endgameReport_title: "Fin del juego",
    end: "Fin",
    restart: "Volver a empezar",
    results: "Resultados",
    completed_words: "Palabras completadas",
    non_completed_words: "Palabras sin completar",
    wrong_attempts: "Intentos erróneos",
    time: "Tiempo",
    start: "Empezar",
    history: "Historial de intentos"
  },
  /**
   * @deprecated Tiene que estar acorde con ISO 639-1:2002
   * */
  cat: {
    button_pasaPalabra: "Passa Paraula",
    button_check: "Comprova",
    textBox_answer: "La teva resposta aquí :)",
    endgameReport_title: "Fi de joc",
    end: "Fi",
    restart: "Torna a començar",
    results: "Resultats",
    completed_words: "Paraules completades",
    non_completed_words: "Paraules sense completar",
    wrong_attempts: "Intents erronis",
    time: "Temps",
    start: "Començar",
    history: "Historial d'intents"
  },
  // Catalan repetido para estar acorde con  ISO 639-1:2002
  ca: {
    button_pasaPalabra: "Passa Paraula",
    button_check: "Comprova",
    textBox_answer: "La teva resposta aquí :)",
    endgameReport_title: "Fi de joc",
    end: "Fi",
    restart: "Torna a començar",
    results: "Resultats",
    completed_words: "Paraules completades",
    non_completed_words: "Paraules sense completar",
    wrong_attempts: "Intents erronis",
    time: "Temps",
    start: "Començar",
    history: "Historial d'intents"
  },
  en: {
    button_pasaPalabra: "Next Word",
    button_check: "Check",
    textBox_answer: "Your answer here :)",
    endgameReport_title: "End of the game",
    end: "End",
    restart: "Restart",
    results: "Results",
    completed_words: "Completed words",
    non_completed_words: "Non completed words",
    wrong_attempts: "Wrong attempts",
    time: "Time",
    start: "Start",
    history: "Attempt history"
  },
  de: {
    button_pasaPalabra: "Nächtes Wort",
    button_check: "Check",
    textBox_answer: "Deine Antwort hier :)",
    endgameReport_title: "Ende des Spiels",
    end: "Ende",
    restart: "Neustart",
    results: "Ergebnisse",
    completed_words: "Richtige Wörte",
    non_completed_words: "Nicht beantwortet",
    wrong_attempts: "Falsche Versuche",
    time: "Zeit",
    start: "Start",
    history: "Versuchsverlauf"
  },
  gl: {
    button_pasaPalabra: "Pasapalabra",
    button_check: "Comproba",
    textBox_answer: "A túa resposta aquí :)",
    endgameReport_title: "Fin do xogo",
    end: "Fin",
    restart: "Volver empezar",
    results: "Resultados",
    completed_words: "Palabras completadas",
    non_completed_words: "Palabras sen completar",
    wrong_attempts: "Intentos errados",
    time: "Tempo",
    start: "Comezar",
    history: "Historial de intentos"
  },
  eu: {
    button_pasaPalabra: "Pasapalabra",
    button_check: "Egiaztatu",
    textBox_answer: "Zure erantzuna hemen :)",
    endgameReport_title: "Jokoaren amaiera",
    end: "Azken",
    restart: "Berriro harrotu",
    results: "Emaitzak",
    completed_words: "Hitz osatuak",
    non_completed_words: "Osatu gabeko hitzak",
    wrong_attempts: "Saiakera okerrak",
    time: "Denbora",
    start: "Hasi",
    history: "Saiakeren historia"
  }
};
