import styled from "styled-components";
import { rotate } from "constants/animations";

export default styled.div`
    display: inline-block;
    width: ${props => props.size || "2rem"};
    height: ${props => props.size || "2rem"};
    vertical-align: text-bottom;
    border: .25em solid ${props => props.color || "black"};
    border-right: .25em solid transparent;
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;
`