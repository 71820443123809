import React from 'react';
import styled from 'styled-components'
import OutsideClickHandler from '../utils/OutsideClickHandler'
import {SHADOW, BORDER_RADIUS} from '../constants/styles'

class AboutPopup extends React.Component  {

    handleClick = (e) => {
        if ( this.node.contains(e.target)){
            return
        }
        this.props.toggleShowAboutPopup()
    }

    render(){

        const {author, globalAuthor, imageUrl} = this.props
        const hostname = new URL(imageUrl).hostname
        
        return (
            <Wrapper>
                <OutsideClickHandler handleClickOutside={this.props.toggleShowAboutPopup}>
                     <div>
                        <p><b>Colaborador de contenido:</b></p>
                        <p><i>{author}</i></p>
                        <p>Imagen extraída de: <i>{hostname}</i></p>
                        <p><b>Aplicación desarrollada y mantenida por:</b></p>
                        <p><i>Román Rey Pedrero & Roger Rey Barbáchano</i></p>
                        <URLGenmagic><a rel="noopener noreferrer" target="_blank" href={"https://"+globalAuthor}>{globalAuthor}</a></URLGenmagic>
                     </div>
                </OutsideClickHandler>
            </Wrapper>
    )}
}

const URLGenmagic = styled.div`
    text-align: center;
    font-size: 1.5rem;
`

const ABOUTCOLOR = 'white';

const Wrapper  = styled.div`
    margin 0 1rem;
    color: black;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 3rem;
    background-color:  ${ABOUTCOLOR};
    padding: 1rem;
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${SHADOW};

    &::after {
        content: "";
        position: absolute;
        top: -16px;
        left: 0;
        margin-left: 5px;
        border-width: 8px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: ${ABOUTCOLOR};
    }
`
export default AboutPopup
