// @flow
import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";

import PasapalabrasApp from "./pages/PasapalabrasApp";
import CreatorApp from "pages/CreatorApp";
import ErrorApp from "pages/ErrorApp";
import OverviewPage from "pages/Overview";

import 'App.css';

// import NavBar from "components/NavBar";
import history from "utils/history";
import { useAuth0 } from "@auth0/auth0-react";

function App() {

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      } catch (e) {
        console.error(e.message);
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  const showAuth = false || isAuthenticated || Boolean(new URLSearchParams(window.location.search).get("auth") || false);

  const authThings = { isAuthenticated, user, accessToken }
  const oldId = new URLSearchParams(window.location.search).get("id")
  console.log("oldId", oldId)
  return (
    <Router history={history} >
      <Switch>
        <Route path="/" exact component={oldId ? PasapalabrasApp : OverviewPage} />
        <Route path="/crear" exact render={props => <CreatorApp {...props} {...authThings} isCreating />} />
        {/* <Route path="/crear" exact render={props => <Fragment>  <NavBar {...authThings} /><CreatorApp {...props} {...authThings} isCreating /></Fragment>} /> */}
        <Route path="/:pasapalabrasId?" exact component={PasapalabrasApp} />
        <Route path="/:pasapalabrasId/edit" exact render={props => <CreatorApp {...props} {...authThings} isEditing />} />
        <Route component={ErrorApp} />
      </Switch>
    </Router>
  );
}

// const _id = searchParams.get("_id")
// const fid = searchParams.get("fid")

export default App;
