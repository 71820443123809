import React from "react";
import { SHADOW, BORDER_RADIUS } from "../../constants/styles";
import styled from "styled-components";

export default class EndGameReport extends React.Component {
  render() {
    const {
      endgameReport_title,
      results_text,
      hits,
      fails,
      minutes,
      solutions,
      history,
      seconds,
      completed_words_text,
      non_completed_words_text,
      wrong_attempts_text,
      time_text,
      history_text,
    } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <WinBox>
          <h2>{endgameReport_title}</h2>
          <h3>{results_text}</h3>
          <table>
            <tbody>
              <tr>
                <td>{completed_words_text}</td>
                <td>:</td>
                <td>
                  {hits} / {solutions.length}
                </td>
              </tr>
              <tr>
                <td>{non_completed_words_text}</td>
                <td>:</td>
                <td>
                  {solutions.length - hits} / {solutions.length}
                </td>
              </tr>
              <tr>
                <td>{wrong_attempts_text}</td>
                <td>:</td>
                <td>{fails}</td>
              </tr>
              <tr>
                <td>{time_text}</td>
                <td>:</td>
                <td>
                  {minutes}m : {seconds}s
                </td>
              </tr>
            </tbody>
          </table>
          <h3>{history_text}</h3>
          {solutions.length &&
            Object.keys(history).map((letterIndex, i) => {
              const letterHistory = history[letterIndex];
              const letterAttempts = letterHistory && letterHistory.attempts;
              const isLetterCompleted =
                letterHistory &&
                letterHistory.isCompleted &&
                history[letterIndex].attempts;
              const letter = solutions[letterIndex].letter;
              const word = solutions[letterIndex].word;

              return (
                <LetterHistory key={i}>
                  <div>
                    <b>{letter + " : "}</b>
                    {isLetterCompleted ? (
                      <span>
                        {word}
                        <Tick>✓</Tick>
                      </span>
                    ) : (
                      <Cross>✘</Cross>
                    )}
                  </div>
                  <Attempts>
                    {letterAttempts.map((attempt) => attempt + ", ")}
                  </Attempts>
                </LetterHistory>
              );
            })}
        </WinBox>
      </div>
    );
  }
}

const LetterHistory = styled.div`
  margin-bottom: 0.5rem;
`;

const Attempts = styled.div`
  overflow-x: auto;
  border-radius: 10px;
  white-space: nowrap;
`;

const Tick = styled.span`
  color: green;
  font-weight: bold;
`;
const Cross = styled.span`
  color: red;
  font-weight: bold;
`;
const WinBox = styled.div`
  z-index: 2;
  padding: 1rem;
  font-size: 20px;
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${SHADOW};
  background-color: white;

  width: 100%;
  max-width: 800px;
`;
