import { BACKGROUND_COLOR } from "constants/colors"
export const pasapalabrasCreatorTemplate = {
    lang: "es",
    title: "",
    level: "",
    author: "",
    image: "https://casaeguia.com/15-large_default/piedras-de-rio-250g.jpg",
    color: BACKGROUND_COLOR,
    solutions: [
        {
            letter: "A",
            phrase: "",
            word: ""
        },
        {
            letter: "B",
            phrase: "",
            word: ""
        },
        {
            letter: "C",
            phrase: "",
            word: ""
        },
    ],
}

export const PASAPALABRASDATATEST = {
    "title": "De todo un poco",
    "level": "Superior",
    "author": "Román Rey",
    "lang": "es",
    "color": "#284148",
    "image": "https://casaeguia.com/15-large_default/piedras-de-rio-250g.jpg",


    "solutions": [
        {
            "letter": "A",
            "phrase": "Cambiar las caracterí­sticas, la esencia o la forma de una cosa.",
            "word": "Alterar"
        },
        {
            "letter": "B",
            "phrase": "Cosa de muy mala calidad, mal hecha, de mal gusto o aburrida.",
            "word": "Bodrio"
        },
        {
            "letter": "C",
            "phrase": "Suceso desdichado en el que se produce gran destrucción y muchas desgracias con grave alteración del desarrollo normal de las cosas.",
            "word": "Catástrofe"
        },
        {
            "letter": "D",
            "phrase": "Hacer desaparecer una cosa de la vista poco a poco por la disgregación y dispersión de sus partes.",
            "word": "Desvanecer"
        },
        {
            "letter": "E",
            "phrase": "Del amor sensual o relacionado con él.",
            "word": "Erótico"
        },
        {
            "letter": "F",
            "phrase": "Acróbata que practica ejercicios de equilibrio sobre el alambre o cuerda floja.",
            "word": "Funámbulo"
        },
        {
            "letter": "G",
            "phrase": "Representación simbólica de los elementos constituidos de un sistema o conjunto, mediante esquemas gráficos.",
            "word": "Grafo"
        },
        {
            "letter": "H",
            "phrase": "Doctrina moral que establece la satisfacción como fin superior y fundamento de la vida. Su principal objetivo consiste en la búsqueda del placer que pueda asociarse con el bien.",
            "word": "Hedonismo"
        },
        {
            "letter": "I",
            "phrase": "Que parece mentira o es imposible o muy difí­cil de creer.",
            "word": "Inverosímil"
        },
        {
            "letter": "J",
            "phrase": "Persona que en la Edad Media iba de pueblo en pueblo divirtiendo a la gente con sus canciones, bailes o juegos a cambio de dinero.",
            "word": "Juglar"
        },
        {
            "letter": "K",
            "phrase": "En la religión budista y en el hinduismo, creencia según la cual toda acción tiene una fuerza dinámica que se expresa e influye en las sucesivas existencias del individuo.",
            "word": "Karma"
        },
        {
            "letter": "L",
            "phrase": "Untar con grasa animal un alimento antes de asarlo.",
            "word": "Lardear"
        },
        {
            "letter": "M",
            "phrase": "Que acostumbra entrometerse en asuntos ajenos.",
            "word": "Metomentodo"
        },
        {
            "letter": "N",
            "phrase": "Falta de cuidado, aplicación y diligencia de una persona en lo que hace, en especial en el cumplimiento de una obligación.",
            "word": "Negligencia"
        },
        {
            "letter": "O",
            "phrase": "Parte de la medicina que se ocupa de la anatomía, la fisioloía y las enfermedades del oí­do, la nariz y la garganta.",
            "word": "otorrinolaringología"
        },
        {
            "letter": "P",
            "phrase": "Nombre que usa una persona en lugar del suyo verdadero, en especial un artista, un escritor, etc.",
            "word": "Pseudónimo"
        },
        {
            "letter": "Q",
            "phrase": "Que se ofende fácilmente por cosas insignificantes a las que da mayor valor o importancia de la que merecen.",
            "word": "Quisquilloso"
        },
        {
            "letter": "R",
            "phrase": "Que es partidario de ideas o instituciones políticas o sociales propias de tiempos pasados.",
            "word": "Retrógrado"
        },
        {
            "letter": "S",
            "phrase": "Tendencia de algunos medios informativos a presentar las noticias destacando sus aspectos más llamativos, aunque sean secundarios, con el fin comercial de provocar asombro o escándalo.",
            "word": "Sensacionalismo"
        },
        {
            "letter": "T",
            "phrase": "Que está hecho sin refinamiento y con materiales vulgares.",
            "word": "Tosco"
        },
        {
            "letter": "U",
            "phrase": "Especialidad médica que se ocupa del estudio, diagnóstico y tratamiento de las patologías que afectan al aparato urinario, glándulas suprarrenales y retroperitoneo de los hombres y el aparato reproductor masculino.",
            "word": "Urología"
        },
        {
            "letter": "V",
            "phrase": "Que puede ser realizado.",
            "word": "Viable"
        },
        {
            "letter": "W",
            "phrase": "Sistema Operativo cuyas actualizaciones demoran en completarse más de lo que usted este pasapalabras.",
            "word": "Windows"
        },
        {
            "letter": "X",
            "phrase": "Contiene la X. Proposición o enunciado tan evidente que se considera que no requiere demostración.",
            "word": "Axioma"
        },
        {
            "letter": "Y",
            "phrase": "Estar echada o tendida horizontalmente {una persona}.",
            "word": "Yacer"
        },
        {
            "letter": "Z",
            "phrase": "Andar vagando de una parte a otra sin trabajar.",
            "word": "Zanganear"
        }
    ]
} 