import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";
import media from "../media";
import {
  DESCRIPTION_COLOR,
  CIRCLE_BACKGROUND_COLOR,
} from "../constants/colors";
import { SHADOW, BORDER_RADIUS } from "../constants/styles";
import { LETTER_SIZE_MOBILE, LETTER_SIZE } from "../constants/sizes";
import { fadein } from "../constants/animations";

class Pasapalabras extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };
  }


  onNextClicked = (e) => {
    e.preventDefault()
    this.setState({
      index: (this.state.index + 1) % Object.keys(this.props.solutions).length
    })

  };

  render() {
    const {
      button_pasaPalabra,
      solutions,
      image,
      isPlaying,
    } = this.props;


    const currentIndex = this.state.index
    const solution = solutions[currentIndex];

    return (
      <WrapperPasapalabras>
        <CircleBg img={image} size={PASAPALABRAS_SIZE} />
        <Ring>
          {solutions.map(({ letter, phrase, word }, i) => (
            <Letter
              size={PASAPALABRAS_SIZE}
              key={i}
              isPassed={undefined}
              isSelected={i === parseInt(currentIndex)}
              degree={(i * 360) / solutions.length + 180}
              index={i}
            >
              <div>{letter}</div>
            </Letter>
          ))}
        </Ring>
        <Content isPlaying={isPlaying} size={PASAPALABRAS_SIZE}>
          {solution && <Fragment>
            {solution.phrase && <DescriptionBox>{solution.phrase}</DescriptionBox>}
            {solution.word && <TextBox>{solution.word}</TextBox>}
          </Fragment>}
          <PasapalabrasButton onClick={this.onNextClicked}>
            {button_pasaPalabra}
          </PasapalabrasButton>
        </Content>
      </WrapperPasapalabras>
    );
  }
}

const PASAPALABRAS_SIZE = 77;


const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  height: ${(props) => props.size}vw;
  justify-content: space-evenly;

  ${media.laptop`
    height: 600px;
  `}
`;

const Letter = styled.div`
    
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: ${SHADOW};
  font-weight: bold;
  position: absolute;
  width: ${LETTER_SIZE_MOBILE}; 
  height: ${LETTER_SIZE_MOBILE}; 
  border-radius: 50%;
  border: 2px solid;
  
  opacity: 0;
  animation: ${fadein} 2s ${(props) => props.index}00ms;
  animation-fill-mode: forwards;  

  transform: rotate(${(props) => props.degree}deg)  translateY(${(props) => props.size / 2}vw) rotate(-${(props) => props.degree}deg);

  ${media.laptop`
    transform: rotate(${(props) => props.degree}deg)  translateY(calc(600px / 2)) rotate(-${(props) => props.degree}deg);
    width: ${LETTER_SIZE}; 
    height: ${LETTER_SIZE}; 
    font-size: 1.5rem;
  `}
      
  background-color: white;
  ${(props) => props.isSelected && `border: 3px solid yellow;`}

`;

const TextBox = styled.div`
  border: solid 1px;
  border-color: black;
  background-color: ${DESCRIPTION_COLOR};
  box-shadow: ${SHADOW};
  border-radius: ${BORDER_RADIUS};
  padding: 10px;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  ${media.tablet`
    font-size: 1rem;
    margin-top: 0;
  `};

`


const DescriptionBox = styled(TextBox)`
  width: 50vw;
  ${media.tablet`
    width: 50vh;
  `};
`;

const PasapalabrasButton = styled(PrimaryButton)`
  // margin-top: 2rem;
  // ${media.tablet`
  //       margin-top: 0;
  //   `};
`;

const WrapperPasapalabras = styled.div`
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Ring = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;

  top: 50%;
  display: flex;
  margin: auto;

  border-radius: 50%;
  text-align: center;
`;
const CircleBg = styled.div`
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${CIRCLE_BACKGROUND_COLOR};
    opacity: ${(props) => (props.img ? 0 : 1)};
    border-radius: 50%;

    transition: opacity 5000ms;
  }

  position: absolute;
  background-image: url(${(props) => props.img});
  background-size: cover;
  opacity: 0.85;
  box-shadow: ${SHADOW};
  border-radius: 50%;
  text-align: center;
  height: ${(props) => props.size}vw;
  width: ${(props) => props.size}vw;

  ${media.laptop`
        height: 600px;
        width: 600px;
    `}
`;

export default Pasapalabras;
