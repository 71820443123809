import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";
import PrimaryButton from "../PrimaryButton";
import media from "../../media";
import {
  DESCRIPTION_COLOR,
  CIRCLE_BACKGROUND_COLOR,
  PLACEHOLDER_COLOR
} from "../../constants/colors";
import { SHADOW, BORDER_RADIUS } from "../../constants/styles";
import {
  LETTER_SIZE_MOBILE, LETTER_SIZE,
  PASAPALABRAS_SIZE_MOBILE, PASAPALABRAS_SIZE_DESKTOP
} from "../../constants/sizes";

import { fadein, flip, rotateScaleUp } from "../../constants/animations";

class Pasapalabras extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();

    this.state = {
      letterStatus: [],
      remaining: Object.keys(props.solutions),
      index: 0,
      answer: "",
      showCongrats: false,
      history: {}
    };
  }

  handleChange = e => {
    this.setState({ answer: e.target.value });
  };
  checkOnEnter = e => {
    const KEYCODE_ENTER = 13;

    if (e.keyCode === KEYCODE_ENTER) {
      if (e.preventDefault) {
        e.preventDefault();
        this.onCheckClicked();
      }
    }
  };

  onStartClicked = () => {
    this.setState({
      letterStatus: [],
      remaining: Object.keys(this.props.solutions),
      index: 0
    });

    this.props.setPlayingStatus(true);
    this.props.setGameEnd(false);
    this.props.resetScores();
    this.props.startTimer();
  };

  skipLetter = () => {
    let remaining = this.state.remaining;
    remaining.push(this.state.remaining.shift());

    this.setState({
      remaining: remaining,
      answer: ""
    });
  };

  passLetter = () => {
    // letter status --> passed
    let letterStatus = this.state.letterStatus;
    letterStatus[this.state.remaining[0]] = true;

    // remove from remaining
    let remaining = this.state.remaining;
    remaining.shift();

    if (remaining.length === 0) {
      this.props.setGameEnd(true);
      this.props.setPlayingStatus(false);
    }

    this.setState({
      letterStatus: letterStatus,
      remaining: remaining,
      answer: ""
    });

    this.props.increaseHits();
  };

  extractContent = s => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  getCorrection = (answer, solution) => {
    const letter = (letter, correct) =>
      '<span style="color:' +
      (correct ? "green" : "red") +
      ';">' +
      letter +
      "</span>";
    let correction = "";
    var i;
    for (i = 0; i < solution.length; ++i) {
      //answer[i] == solution[i]
      const char = answer[i] || "?";

      correction += letter(
        char,
        char.toLowerCase() === solution[i].toLowerCase()
      );
    }
    while (i < answer.length) {
      const char = answer[i] || "?";
      correction += letter(char, false);
      ++i;
    }

    return correction;
  };

  failLetter = () => {
    // letter status --> failed
    let letterStatus = this.state.letterStatus;
    const currentIndex = this.state.remaining[0];
    letterStatus[currentIndex] = false;
    const solution = this.props.solutions[currentIndex].word;
    const answer = this.extractContent(this.state.answer);
    const correction = this.getCorrection(answer, solution.toLocaleLowerCase());
    this.setState({ letterStatus: letterStatus, answer: correction });
    this.props.increaseFails();
  };

  onNextClicked = () => {
    if (this.state.showCongrats) return;
    this.skipLetter();
  };

  sanize = word => {
    return word.trim().toLowerCase();
  };

  onCheckClicked = () => {
    if (this.state.showCongrats) return;
    const currentIndex = this.state.remaining[0];
    const solution = this.props.solutions[currentIndex].word;
    const answer = this.extractContent(this.state.answer);

    if (
      this.sanize(answer) === this.sanize(solution) ||
      (process.env.NODE_ENV === "development" && answer === ".")
    ) {
      this.setState({ showCongrats: true });
      setTimeout(() => {
        this.setState({ showCongrats: false });
        this.props.addToHistory(currentIndex, answer, true);
        this.passLetter();
      }, 2500);
    } else {
      this.props.addToHistory(currentIndex, answer, false);
      this.failLetter();
    }
  };

  render() {
    const {
      button_pasaPalabra,
      button_check,
      textBox_answer,
      start_text,
      solutions,
      image,
      isPlaying,
      isGameEnd
    } = this.props;

    const { remaining, letterStatus, answer, showCongrats } = this.state;

    const currentIndex = remaining[0];
    const solution = solutions[currentIndex];

    return (
      <WrapperPasapalabras>
        <CircleBg img={image} />
        <Ring>
          {solutions.map(({ letter, phrase, word }, i) => (
            <Letter
              key={i}
              isPassed={letterStatus[i]}
              isSelected={i === parseInt(currentIndex)}
              degree={(i * 360) / solutions.length + 180}
              index={i}
            >
              <div>{letter}</div>
            </Letter>
          ))}
        </Ring>
        {
          <Content isPlaying={isPlaying}>
            {isPlaying && (
              <Fragment>
                <PasapalabrasButton onClick={this.onNextClicked}>
                  {button_pasaPalabra}
                </PasapalabrasButton>
                {showCongrats ? (
                  <Congrats>
                    <div>{solution.word}</div>
                    <Tick>✓</Tick>
                  </Congrats>
                ) : (
                    <DescriptionBox>{solution && solution.phrase}</DescriptionBox>
                  )}
                <AnswerBox
                  text={textBox_answer}
                  innerRef={this.contentEditable}
                  html={answer}
                  disabled={false}
                  onChange={this.handleChange}
                  onKeyDown={this.checkOnEnter}
                />
                <CheckButton onClick={this.onCheckClicked}>
                  {button_check}
                </CheckButton>
              </Fragment>
            )}
            {!isPlaying && !isGameEnd && (
              <PrimaryButton onClick={this.onStartClicked}>
                {start_text}
              </PrimaryButton>
            )}
          </Content>
        }
      </WrapperPasapalabras>
    );
  }
}

const Tick = styled.div`
  color: green;
  animation: ${rotateScaleUp} 0.5s linear 0.5s;
`;

const Congrats = styled.div`
  display: flex;
  background: white;
  padding: 0.75rem;
  border: 2px solid black;
  border-radius: ${BORDER_RADIUS};
  font-size: 2.5rem;
  ${media.tablet`
    font-size: 4rem;
  `}

  box-shadow: ${SHADOW};
  animation: ${flip} 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  height: ${PASAPALABRAS_SIZE_MOBILE};
  justify-content: ${props => props.isPlaying ? "flex-start" : "space-around"};
  ${media.tablet`
    justify-content: space-around;
    `}
  ${media.laptop`
      height: ${PASAPALABRAS_SIZE_DESKTOP};
  `}
`;

const Letter = styled.div`
    
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: ${SHADOW};
  font-weight: bold;
  position: absolute;
  width: ${LETTER_SIZE_MOBILE};
  height: ${LETTER_SIZE_MOBILE};
  border-radius: 50%;
  border: 2px solid;

  opacity: 0;
  animation: ${fadein} 2s ${props => props.index}00ms;
  animation-fill-mode: forwards;

  transform: rotate(${props => props.degree}deg)  translateY(calc(${PASAPALABRAS_SIZE_MOBILE} / 2)) rotate(-${props => props.degree}deg);

  ${media.laptop`
  transform: rotate(${props => props.degree}deg)  translateY(calc(${PASAPALABRAS_SIZE_DESKTOP} / 2)) rotate(-${props => props.degree}deg);
    width: ${LETTER_SIZE};
    height: ${LETTER_SIZE};
    font-size: 1.5rem;
  `}

  background-color: ${props => props.isPassed === undefined ? "white" : props.isPassed ? "green" : "red"};
  ${props => props.isSelected && `border: 3px solid yellow;`}
`;

const AnswerBox = styled(ContentEditable)`
  border: 1px solid black;
  background-color: white;
  box-shadow: ${SHADOW};
  padding: 0.5rem;
  width: 100%;
  font-size: 1.5rem;

  &:empty:not(:focus):before{
    color: ${PLACEHOLDER_COLOR};
    content: '${props => props.text}';
  }

  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  ${media.tablet`
    position: relative;
    text-align: center;
    width: auto;
    min-width: 250px;
    border-radius: ${BORDER_RADIUS};
  `};
`;

const DescriptionBox = styled.div`
  border: solid 1px;
  border-color: black;
  background-color: ${DESCRIPTION_COLOR};
  box-shadow: ${SHADOW};
  border-radius: ${BORDER_RADIUS};
  padding: 10px;
  margin-top: 0.5rem;
  width: 55vw;
  font-size: 0.85rem;

  ${media.tablet`
    width: 40vh;
    margin-top: 0;
    font-size: 1.125rem;
  `};
`;

const CheckButton = styled(PrimaryButton)`
  position: fixed;
  bottom: 3.5rem;
  ${media.tablet`
      position: relative;
      bottom: auto;
  `};
`;
const PasapalabrasButton = styled(PrimaryButton)`
  margin-top: 2rem;
  ${media.tablet`
      margin-top: 0;
  `};
`;

const WrapperPasapalabras = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Ring = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;

  top: 50%;
  display: flex;
  margin: auto;

  border-radius: 50%;
  text-align: center;
`;

const CircleBg = styled.div`
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${CIRCLE_BACKGROUND_COLOR};
    opacity: ${props => props.img ? 0 : 1};
    border-radius: 50%;

    transition: opacity 5000ms;
  }

  position: absolute;
  background-image: url(${props => props.img});
  background-size: cover;
  opacity: 0.85;
  box-shadow: ${SHADOW};
  border-radius: 50%;
  text-align: center;

  height: ${PASAPALABRAS_SIZE_MOBILE};
  width:  ${PASAPALABRAS_SIZE_MOBILE};
  
  ${media.laptop`
    height: ${PASAPALABRAS_SIZE_DESKTOP};
    width:  ${PASAPALABRAS_SIZE_DESKTOP};
  `}
`;

export default Pasapalabras;
