import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from "./config/auth-config.json";

ReactDOM.render(
<Auth0Provider
    domain={authConfig.domain}
    clientId={authConfig.clientId}
    redirectUri={window.location.href}
    audience={authConfig.audience}
    scope={authConfig.scope}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();