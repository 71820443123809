import styled from "styled-components";
import {
  PRIMARY_BUTTON_COLOR,
  PRIMARY_BUTTON_COLOR_ACTIVE
} from "../constants/colors";
import { SHADOW, BORDER_RADIUS } from "../constants/styles";
import media from "../media";

const PrimaryButton = styled.button`

  font-weight: bold;
  color: white;
  border: none;
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${SHADOW};
  min-height: 48px;
  min-width: 48px;
  letter-spacing: 1px;
  padding: 0.75rem;
  background-color: ${PRIMARY_BUTTON_COLOR};
  &:active {
    background-color: ${PRIMARY_BUTTON_COLOR_ACTIVE};
  }

  &:focus {
    outline: 0;
  }
  ${media.tablet`
    font-size: 1.25rem;
  `}
`;

export default PrimaryButton;
