import { keyframes } from "styled-components";

export const fadein = keyframes`
  from { opacity: 0;}
  to { opacity: 1; }
`;

export const typewritter = keyframes`
  from { width: 0;}
  to { width: 100%; }
`;

export const flip = keyframes`

  /* ----------------------------------------------
  * Generated by Animista on 2020-3-28 12:6:41
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation flip-horizontal-top
   * ----------------------------------------
   */

  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0);
  }
`;

export const rotateScaleUp = keyframes`
/* ----------------------------------------------
 * Generated by Animista on 2020-3-28 12:14:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */

  0% {
    transform: scale(1) rotateZ(0);
  }
  50% {
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    transform: scale(1) rotateZ(360deg);
  }
`;

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
