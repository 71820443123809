import React from 'react';
import styled from 'styled-components'
import Timer from './Timer'
import media from '../media';
import { SHADOW } from "../constants/styles";


const Scoreboard = (props) => {

    const { hits, fails, seconds, minutes } = props

    return (
        <Items>
            <Item left> <Timer minutes={minutes} seconds={seconds} /></Item>
            <Item middle >  <span >{hits}</span> <i className="material-icons" style={{ color: 'green' }}>done</i></Item>
            <Item right><span >{fails}</span> <i className="material-icons" style={{ color: 'red' }}>clear</i></Item>
        </Items>
    )
}


const Items = styled.div`
    height: 48px;
    display: flex;
`
const Item = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid;
    box-shadow: ${SHADOW};
    font-size: 1rem;    
    padding: 0.4rem;
    
    ${media.tablet`
        font-size: 1.5rem;    
        padding: 0.8rem;
    `};

    ${ props => {
        if (props.left) return `
        border-top-left-radius: 5px; 
        border-bottom-left-radius: 5px;
        `
        else if (props.middle) return `
        border-left: none;
        border-right: none;
        `
        else if (props.right) return `
        border-top-right-radius: 5px; 
        border-bottom-right-radius: 5px
    `}};
`



export default Scoreboard