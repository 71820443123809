import React, { Fragment } from 'react';

const Timer = (props) => {
    const { minutes, seconds } = props
    return (
        <Fragment>
            <i className="material-icons">alarm</i>
            <span >{("0" + minutes).slice(-2)}</span>:
            <span >{("0" + seconds).slice(-2)}</span>
        </Fragment>
    )
}
export default Timer